import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import App from 'App.js';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import "./lang/config";
import { RecoilRoot } from 'recoil';

// @see https://qiita.com/saki-engineering/items/b327f93fe7f027913bd7
Amplify.configure({
  Auth: {
      region: 'ap-northeast-1',
      userPoolId: 'ap-northeast-1_RR8eyHouW',
      userPoolWebClientId: '7t9sbn1u7bk8a81tfmkqckdjmk',
      identityPoolId: 'ap-northeast-1:716848d0-a174-436c-9eb2-bef7a0d99b85',
  },
  API: {
    endpoints: [
      {
        name: "IM_Lambda",
        endpoint: process.env.REACT_APP_API_URL
      },
    ],
}
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();