/**
 * S3のバケットURL
 */
export const S3_URL = 'https://liana-sys.s3.ap-northeast-1.amazonaws.com/pub/';

/**
 * AOIのサマリ表示で使用する斜面変動ランクの配列(日本語)
 */
export const SLOPE_FLUCTUATION_RANK = [
  '',
  '変動 A’’（警戒変動）', // = 1
  '変動 A’（注意変動）', // = 2
  '変動 A（確定変動）', // = 3
  '変動 B（準確定変動）', // = 4
  '変動 C（潜在変動）以下', // = 5
];

/**
 * AOIのサマリ表示で使用する斜面変動ランクの配列(英語)
 */
export const SLOPE_FLUCTUATION_RANK_EN = [
  '',
  'Variation A´´(vigilance variation)', // = 1
  'Variation A´(caution variation)', // = 2
  'Variation A(confirmed variation)', // = 3
  'Variation B(semi-confirmed variation)', // = 4
  'Variation C(potential variation)and below', // = 5
];

/**
 * AOIのサマリ表示で使用する斜面変動ランクの配列(インドネシア語)
 */
export const SLOPE_FLUCTUATION_RANK_ID = [
  '',
  'Variasi A´´(variasi kesiagaan)', // = 1
  'Variasi A´(variasi peringatan)', // = 2
  'Variasi A(variasi terkonfirmasi)', // = 3
  'Variasi B(variasi semi-terkonfirmasi)', // = 4
  'Variasi C(variasi potensial) ke bawah', // = 5
];