import React, { useState, useEffect } from 'react';
import ProjectsDashboardTable from 'components/dashboard/ProjectsDashboardTable';
import { postRequest, changeDateFormat } from 'utils/_function';
import { makeStyles } from '@material-ui/core/styles';
import Header from 'components/dashboard/Header';
import PurchaseAlertPopup from 'components/map/PurchaseAlertPopup';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1.5em',
  },
  containerTop: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1.5em 1.5em 0',
  },
  leftTitle: {
    margin: 0,
  },
}));

/** Project 一覧ページ */
export default function ProjectsDashboard(props) {

  const {
    user,
    userName,
    accountGroupId,
    accountGroupName,
    isShowPurchaseAlertPopup,
    setIsShowPurchaseAlertPopup,
    currentLang
  } = props;

  const classes = useStyles();
  const [projectsTableData, setProjectsTableData] = useState([]);
  const [alertMesseageProjectInfoList, setAlertMesseageProjectInfoList] =
    useState(false);
  const [addLoadingList, setAddLoadingList] = useState(false);
  const { t } = useTranslation();

  /** Project一覧を取得 */
  const fetchProjectInfoList = async () => {
    setAddLoadingList(true);
    setAlertMesseageProjectInfoList(false);
    const errorMessage = '一覧が取得できません。';
    const params = {
      accountGroupId: accountGroupId,
    };
    const response = await postRequest(
      'IM_Lambda',
      '/im-getprojectinfolist',
      params
    );
    if (response.errorMessage) {
      setAddLoadingList(false);
      setAlertMesseageProjectInfoList(errorMessage);
      return;
    }
    if (!response.statusCode) {
      setAddLoadingList(false);
      setAlertMesseageProjectInfoList(errorMessage);
      return;
    } else {
      setAddLoadingList(false);
    }

    switch (response.statusCode) {
      case 'N0000': // 正常終了
        createTableData(response.projectInfoList).then(value => {
          setProjectsTableData(value);
          setAlertMesseageProjectInfoList(false);
        });
        // setProjectsTableData(createTableData(response.projectInfoList));
        break;
      case 'E0140': // 対象のプロジェクトが存在しない
        setAlertMesseageProjectInfoList(errorMessage);
        break;
      case 'E0180': // 対象のユーザが存在しない
        setAlertMesseageProjectInfoList(errorMessage);
        break;
      case 'E0250': // バリデーションチェックによるエラー
        setAlertMesseageProjectInfoList(errorMessage);
        break;
      case 'E0260': // コード内で指定されたデータが、タグごと入力JSONにない
        setAlertMesseageProjectInfoList(errorMessage);
        break;
      case 'E0270': // コード内で指定されたデータについて、値が入力JSONにない
        setAlertMesseageProjectInfoList(errorMessage);
        break;
      case 'E0280': // 対象データ取得エラー
        setAlertMesseageProjectInfoList(errorMessage);
        break;
      default:
        setAlertMesseageProjectInfoList(errorMessage);
        break;
    }
  };

  /** IM_GetProjectInfoList から取得したデータをダッシュボード表示用に整形 */
  const createTableData = async (projectsInfo) => {
    let projectData = [];
    for (let i = 0; i < projectsInfo.length; i++){
      projectData.push({
        projectId: projectsInfo[i].projectId,
        refProjectId: projectsInfo[i].refProjectId,
        latitudeCenter: projectsInfo[i].pjLatCenter,
        longitudeCenter: projectsInfo[i].pjLngCenter,
        category: projectsInfo[i].projectCategory,
        henniMax: projectsInfo[i].valAnalysisMax,
        henniAve: projectsInfo[i].valAnalysisAve,
        henniCountOverThreshold: projectsInfo[i].valAnalysisCount,
        henniPercentageOverThreshold: projectsInfo[i].valAnalysisCountRate,
        velocityMax: projectsInfo[i].valVelocityMax,
        velocityAve: projectsInfo[i].valVelocityAve,
        velocityCountOverThreshold: projectsInfo[i].valVelocityCount,
        velocityPercentageOverThreshold: projectsInfo[i].valVelocityCountRate,
        name: projectsInfo[i].projectName,
        createDateTime: changeDateFormat(projectsInfo[i].createDateTime),
        updatedAt: changeDateFormat(projectsInfo[i].updateDateTime),
        aoiCount: projectsInfo[i].countAoi,
        target: projectsInfo[i].Target,
        status: projectsInfo[i].Status,
        remarks: projectsInfo[i].Remarks,
        dates: projectsInfo[i].Dates,
        purchaseType: projectsInfo[i].PurchaseType
      })
    }
    return projectData.map(function(v) {return v;});
  };

  /** プロジェクト一覧取得 */
  useEffect(() => {
    if (!accountGroupId) return;
    fetchProjectInfoList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountGroupId]);

  return (
    <>
      <Header
        t={t}
        user={user}
        userName={userName}
        accountGroupName={accountGroupName}
      ></Header>
      <div className="container">
        <div className={classes.containerTop}>
          <h2 className={classes.leftTitle}>{t("dashboard.title")}</h2>
          {/**<Button variant="contained" color="primary">
            New Project
          </Button>**/}
        </div>
        <div className={classes.container}>
          <ProjectsDashboardTable
            t={t}
            currentLang={currentLang}
            projectsTableData={projectsTableData}
            alertMesseageProjectInfoList={alertMesseageProjectInfoList}
            addLoadingList={addLoadingList}
            accountGroupId={accountGroupId}
            setIsShowPurchaseAlertPopup={setIsShowPurchaseAlertPopup}
          ></ProjectsDashboardTable>
        </div>
      </div>
      {/* 非購入AOIアラートポップアップ */}
      <PurchaseAlertPopup
        isShowPurchaseAlertPopup={isShowPurchaseAlertPopup}
        setIsShowPurchaseAlertPopup={setIsShowPurchaseAlertPopup}
      />
    </>
  );
}
