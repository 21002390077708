// ログイン画面　日本語
export const login_ja = {
  'ja': {
    'Account recovery requires verified contact information': 'アカウントの復旧には、確認済みの連絡先情報が必要です',
    'Back to Sign In': 'サインイン画面に戻る',
    'CHANGE': '変更',
    'Change': '変更',
    'Change Password': 'パスワードを変更する',
    'Confirm': '確認',
    'Confirm Sign Up': 'サインアップの確認',
    'Confirmation Code': '確認コード',
    'Create Account': '新規登録',
    'Create a new account': 'アカウントの新規登録',
    'Create account': '新規登録',
    'Email Address *': 'メールアドレス *',
    'Email': 'メールアドレス *',
    'Enter code': 'コードを入力してください',
    'Enter your code': '確認コードを入力してください',
    'Enter your email address': 'メールアドレスを入力してください',
    'Enter your password': 'パスワードを入力してください',
    'Enter your new password': '新しいパスワードを入力してください',
    'Enter your username': 'メールアドレスを入力してください',
    'Forgot your password?': 'パスワードをお忘れの方 ',
    'Have an account? ': 'アカウント登録済みの方 ',
    'Hello': 'こんにちは ',
    'Incorrect username or password.': 'メールアドレスまたはパスワードが異なります。',
    'Invalid verification code provided, please try again.': '無効な確認コードです。再試行してください。',
    "1 validation error detected: Value at 'username' failed to satisfy constraint: Member must have length less than or equal to 128": '1つの検証エラーが検出されました：「username」の値が制約を満たせませんでした：メンバーの長さが128以下である必要があります',
    "1 validation error detected: Value at 'userName' failed to satisfy constraint: Member must have length less than or equal to 128": '1つの検証エラーが検出されました：「username」の値が制約を満たせませんでした：メンバーの長さが128以下である必要があります',
    'Lost your code? ': 'コードを紛失した方 ',
    'New password': '新しいパスワード',
    'No account? ': 'アカウント未登録の方 ',
    'Password *': 'パスワード *',
    'Password cannot be empty': 'パスワードを空にすることはできません',
    'Password does not conform to policy: Password must have symbol characters': 'パスワードがポリシーに準拠していません：パスワードには記号文字が必要です',
    'Phone Number': '電話番号',
    'Resend Code': '確認コードの再送',
    'Reset password': 'パスワードのリセット',
    'Reset your password': 'パスワードのリセット',
    'Send Code': 'コードの送信',
    'skip': 'スキップ',
    'Skip': 'スキップ',
    'SKIP': 'スキップ',
    'sKIP': 'スキップ',
    'SkIP': 'スキップ',
    'SKiP': 'スキップ',
    'SKIp': 'スキップ',
    'skIP': 'スキップ',
    'skiP': 'スキップ',
    'SKip': 'スキップ',
    'Sign In': 'サインイン',
    'Sign Out': 'サインアウト',
    'Sign in': 'サインイン',
    'Sign in to your account': 'アカウントにサインインします',
    'Submit': '送信',
    'User does not exist': 'ユーザーが存在しません',
    'Username *': 'メールアドレス *',
    'Username cannot be empty': 'メールアドレスは必須入力です',
    'Username/client id combination not found.': 'メールアドレスが見つかりません',
    'Verification code': '検証コード',
    'Verify': '確認',
    }
};

// ログイン画面　インドネシア語
export const login_id = {
  'id': {
    "Account recovery requires verified contact information": "Pemulihan akun memerlukan informasi kontak yang terverifikasi",
    "Back to Sign In": "Kembali ke Masuk",
    "CHANGE": "UBAH",
    "Change Password": "Ubah Kata Sandi",
    "Confirm": "Konfirmasi",
    "Confirm Sign Up": "Konfirmasi Pendaftaran",
    "Confirmation Code": "Kode Konfirmasi",
    "Create Account": "Buat Akun",
    "Create a new account": "Buat akun baru",
    "Email Address *": "Alamat Email *",
    "Enter code": "Masukkan kode",
    "Enter your code": "Masukkan kode Anda",
    "Enter your email address": "Masukkan alamat email Anda",
    "Enter your password": "Masukkan kata sandi Anda",
    "Enter your new password": "Masukkan kata sandi baru Anda",
    "Enter your username": "Masukkan nama pengguna Anda",
    "Forgot your password?": "Lupa kata sandi Anda?",
    "Have an account?": "Punya akun?",
    "Hello": "Halo",
    "Incorrect username or password.": "Nama pengguna atau kata sandi salah.",
    "Invalid verification code provided, please try again.": "coba lagi",
    "1 validation error detected: Value at 'username' failed to satisfy constraint: Member must have length less than or equal to 128" :"1 kesalahan validasi terdeteksi: Nilai di 'nama pengguna' gagal memenuhi batasan: Anggota harus memiliki 128 karakter atau kurang",
    "Lost your code?" :"Kehilangan kode Anda?",
    "New password" :"Kata sandi baru",
    "No account?" :"Tidak punya akun?",
    "Password cannot be empty" :"Kata sandi tidak boleh kosong",
    "Password does not conform to polic password must have symbol characters:" :"Kata sandi tidak sesuai dengan kebijakan",
    "Phone Number" :"Nomor Telepon",
    "Resend Code" :"Kirim Ulang Kode",
    "Reset password" :"Reset kata sandi",
    "Send Code" :"Kirim Kode",
    'skip': 'Lewati',
    'Skip': 'Lewati',
    'SKIP': 'Lewati',
    'sKIP': 'Lewati',
    'SkIP': 'Lewati',
    'SKiP': 'Lewati',
    'SKIp': 'Lewati',
    'skIP': 'Lewati',
    'skiP': 'Lewati',
    'SKip': 'Lewati',
    "Sign In" :"Masuk",
    "Sign Out" :"Keluar",
    "Sign in to your account" :"Masuk ke akun Anda",
    "Submit" :"Kirim",
    "User does not exist" :"Pengguna tidak ada",
    "Username cannot be empty" :"Nama pengguna tidak boleh kosong",
    "Username/client id combination not found." :"Kombinasi nama pengguna/id klien tidak ditemukan.",
    "Verification code" :"Kode verifikasi",
    "Verify" :"Verifikas",
  }
};