import { useState, useRef } from 'react';
import { postRequest } from 'utils/_function';

//管理者コメントを保存・リセットする機能のロジック
export const useAdminComment = (projectId) => {
  const inputAdminCommentRef = useRef(); // コメント入力欄で入力された値を格納
  const [aoiClickFlag, setAoiClickFlag] = useState(false); // 管理者コメント入力UIのdisabledのフラグ
  const [adminCommentAoiId, setAdminCommentAoiId] = useState(); // クリックしたAOIのIDを格納
  const [registerAdminCommentLoadingFlag, setRegisterAdminCommentLoadingFlag] = useState(false); // 管理者コメント登録時のローディングフラグ
  const [regsiterFetchFlag, setRegsiterFetchFlag] = useState(false); // 管理者コメント登録用のAPIを叩いたフラグ(snakbarを表示するロジックが走る)
  const [adminCommentResultMessage, setAdminCommentResultMessage] = useState(''); // 管理者コメント登録用のAPIを叩いた結果のメッセージを格納
  const [isSuccessAdminComment, setIsSuccessAdminComment] = useState(false); // 管理者コメント登録が成功or失敗のフラグ

  //コメント入力欄で入力された値を変数に保存
  const handleChangeInputAdminComment = (value) => {
    inputAdminCommentRef.current = value;
  }

  //保存ボタン押下時に管理者コメントをDBへ保存する
  const registerAdminComment = async () =>{
    setRegisterAdminCommentLoadingFlag(true)
    const params = {
      projectId: String(projectId),
      aoiId: Number(adminCommentAoiId),
      comment: String(inputAdminCommentRef.current),
      commentFlag: true,
    }
    const response = await postRequest('IM_Lambda', '/im-addaoiadmincomment', params);
    if (response.errorMessage) {
      setAdminCommentResultMessage('管理者コメントの保存に失敗しました。');
      setIsSuccessAdminComment(false);
      return;
    }
    if (!response.statusCode) {
      setAdminCommentResultMessage('管理者コメントの保存に失敗しました。');
      setIsSuccessAdminComment(false);
      return;
    }

    switch (response.statusCode) {
      case "N0000": // 正常終了
        setAdminCommentResultMessage('管理者コメントを保存しました。');
        setIsSuccessAdminComment(true);
        break;
      case "E0270": //　必須項目がない
        setAdminCommentResultMessage('管理者コメントの保存に失敗しました。');
        setIsSuccessAdminComment(false);
        break;
      case "E0260": // その他のエラー
        setAdminCommentResultMessage('管理者コメントの保存に失敗しました。');
        setIsSuccessAdminComment(false);
        break;
      default:
        setAdminCommentResultMessage('管理者コメントの保存に失敗しました。');
        setIsSuccessAdminComment(false);
        break;
    }
    setRegsiterFetchFlag(true);
    setRegisterAdminCommentLoadingFlag(false);
  }

  //リセットボタン押下時に管理者コメントをリセットする
  const resetRegisterAdminComment = async () =>{
    let adminCommentInput = document.getElementById('adminCommentInput');
    if(!adminCommentInput.value) return; // 管理者コメントが空の場合は以下を実行しない

    setRegisterAdminCommentLoadingFlag(true)
    const params = {
      projectId: String(projectId),
      aoiId: Number(adminCommentAoiId),
      comment: null,
      commentFlag: false,
    }
    const response = await postRequest('IM_Lambda', '/im-addaoiadmincomment', params);
    if (response.errorMessage) {
      setAdminCommentResultMessage('管理者コメントのリセットに失敗しました。');
      setIsSuccessAdminComment(false);
      return;
    }
    if (!response.statusCode) {
      setAdminCommentResultMessage('管理者コメントのリセットに失敗しました。');
      setIsSuccessAdminComment(false);
      return;
    }

    switch (response.statusCode) {
      case "N0000": // 正常終了

        // 入力値を削除
        adminCommentInput.value = '';
        inputAdminCommentRef.current = '';

        setAdminCommentResultMessage('管理者コメントをリセットしました。');
        setIsSuccessAdminComment(true);
        break;
      case "E0270": //　必須項目がない
        setAdminCommentResultMessage('管理者コメントのリセットに失敗しました。');
        setIsSuccessAdminComment(false);
        break;
      case "E0260": // その他のエラー
        setAdminCommentResultMessage('管理者コメントのリセットに失敗しました。');
        setIsSuccessAdminComment(false);
        break;
      default:
        setAdminCommentResultMessage('管理者コメントのリセットに失敗しました。');
        setIsSuccessAdminComment(false);
        break;
    }
    setRegsiterFetchFlag(true);
    setRegisterAdminCommentLoadingFlag(false);
  }

  //保存ボタン押下時の処理
  const handleClickAdminCommentKeep = () => {
    if(!inputAdminCommentRef.current) return; // 管理者コメントが空の場合は以下を実行しない
    registerAdminComment();
  }

  return{
    aoiClickFlag,
    registerAdminCommentLoadingFlag,
    regsiterFetchFlag,
    adminCommentResultMessage,
    isSuccessAdminComment,
    setAoiClickFlag,
    setAdminCommentAoiId,
    setRegsiterFetchFlag,
  }
}